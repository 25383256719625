<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>자주하는 질문</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a>SUPPORT</a></li>
                        <li><span>자주하는 질문</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section faq">
            <div class="innWrap subTab01 ">

                <div class="flex">
                    <div class="nav">
                        <ul>
                            <li><a :class="{ active: active }" @click="activeOnOff">검색으로 찾기</a></li>
                            <li><a :class="{ active: !active }" @click="activeOnOff">카테고리로 찾기</a></li>
                        </ul>
                    </div>
                    <div class="con">
                      <!-- <transition name="fade"> -->
                        <div id="tab01" v-show="this.active">
                            <div class="lineBox">
                                <div class="searchBox-select">
                                    <div class="select select-inner w288px" id="svcmodfi-title-01">
                                        <span class="placeholder"><span class="fcGy">선택하세요.</span></span>
                                        <div class="list">
                                            <ul>
                                                <li v-for="item in type" :key="item.value" @click="selectValue('type', item.value)" :value="item.value">{{item.title}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input type="text" id="svcmodfi-title-02" class="input-line" v-model="searchWord">
                                    <a class="btn-md01 btn-primary" @click="getCustFaqInfoSearch('init')">조회</a>
                                </div>
                            </div>
                            <div id="Accordion_wrap" class="faq-accordion mgt40">
                                <ul v-for="item in searchFaqList" :key="item.seq" v-show="searchFaqList">
                                    <li id="faq-que" class="que" @click="viewFaq($event)">
                                        <span class="num">{{item.num}}</span>
                                        <span class="tit">
                                          {{item.faq_title}}
                                        </span>
                                    </li>
                                    <li class="anw">
                                        <p>
                                            {{item.faq_detail_info}}
                                        </p>
                                    </li>
                                </ul>
                                <ul v-show="!searchFaqList">
                                    <li id="faq-que" class="que">
                                        <span class="num"></span>
                                        <span class="tit" style="margin-left: 400px; !important">
                                          검색결과가 없습니다.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- </transition> -->
                        <!-- <transition name="fade"> -->
                        <div id="tab02" v-show="!this.active">
                            <div class="subToptab">
                                <ul class="topTab-navWrap">
                                    <li :class="activeTab == '1' ? 'topTab-nav on' : 'topTab-nav'" @click="getCustFaqInfoSearch('c', '1')">기업인터넷</li>
                                    <li :class="activeTab == '5' ? 'topTab-nav on' : 'topTab-nav'" @click="getCustFaqInfoSearch('c', '5')">Flexline</li>
                                    <li :class="activeTab == '2' ? 'topTab-nav on' : 'topTab-nav'" @click="getCustFaqInfoSearch('c', '2')">GiGA office</li>
                                    <li :class="activeTab == '3' ? 'topTab-nav on' : 'topTab-nav'" @click="getCustFaqInfoSearch('c', '3')">KORNET</li>
                                    <li :class="activeTab == '4' ? 'topTab-nav on' : 'topTab-nav'" @click="getCustFaqInfoSearch('c', '4')">VPN</li>
                                </ul>
                                <div class="topTab-conWrap">
                                    <div class="topTab-con on">
                                        <div id="category01" class="faq-accordion mgt40">
                                            <ul v-for="item in searchFaqList" :key="item.seq" v-show="searchFaqList">
                                                <li class="que" @click="viewFaq($event)">
                                                    <span class="num">{{item.num}}</span>
                                                    <span class="tit">
                                                    {{item.faq_title}}
                                                    </span>
                                                </li>
                                                <li class="anw">
                                                    <p>
                                                        {{item.faq_detail_info}}
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul v-show="!searchFaqList">
                                                <li id="faq-que" class="que">
                                                    <span class="num"></span>
                                                    <span class="tit" style="margin-left: 400px; !important">
                                                    검색결과가 없습니다.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </transition> -->
                    </div>
                </div>
                <div class="btnBottomArea">
                    <a @click="$router.push('/goSupCns000')" class="btn-lg fix btn-primary">문의하기</a>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
    components: {
        HeaderLayout,
        FooterLayout
    },
    data () {
        return {
            active: true,
			activeTab: '1',
            type: [
                {title: '제목', value: 'title'},
                {title: '내용', value: 'content'}
            ],
            searchType : 'title',
            searchWord : '',
            fixedType : '',
            fixedWord : '',
            searchFaqList : '',
			selectedCategori: 'KI'
        }
    },
    mounted: function () {
        this.getCustFaqInfoSearch('init')
    },
    methods: {
        selectValue(type, value) {
            this.searchType = value;
        },
        // 검색으로 찾기
        getCustFaqInfoSearch: async function (type, value) {
            let payload = {}
            this.searchFaqList = ''
            if(type == 'c') {
                let type = ''
                switch (value) {
                    case '1':
                        this.activeTab = '1'
                        type = 'GO'
                        break;
                    case '2':
                        this.activeTab = '2'
                        type = 'KI'
                        break;
                    case '3':
                        this.activeTab = '3'
                        type = 'KO'
                        break;
                    case '4':
                        this.activeTab = '4'
                        type = 'VN'
                        break;
                    case '5':
                        this.activeTab = '5'
                        type = 'FL'
                        break;
                }
                payload = {
                    "page_num" : 1,
                    // "page_size" : this.page_size,
                    "faq_cat_type" : type
                }
            } else {
                let title = ''
                let content = ''

                this.searchType === 'title' ? title = this.searchWord : content = this.searchWord
                payload = {
                    "page_num" : 1,
                    "faq_title" : title,
                    "faq_detail_info" : content
                }
            }

            await this.$store.dispatch('gigaoffice/getCustFaqInfo', payload).then((res) => {
                if(res.data.response.results.length > 0) {
                    this.searchFaqList = res.data.response.results
                }
            })
            .catch(error => {
                console.log(error)
            })
        }
        , activeOnOff () {
            this.active = this.active ? false : true
            if(this.active){
                this.getCustFaqInfoSearch('init')
            }else{
                this.getCustFaqInfoSearch('c', '1')
            }
        }
        , viewFaq (event) {
            event.currentTarget.classList.toggle('on')
            event.currentTarget.nextElementSibling.classList.toggle('on')
        }
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.anw.on{
    display: list-item;
}
</style>